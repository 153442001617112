import * as React from 'react';
import classNames from 'classnames';
import Col from '../grid/col';
import { FormContext } from './context';
import { useLocaleReceiver } from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
const FormItemLabel = ({ prefixCls, label, htmlFor, labelCol, labelAlign, colon, required, requiredMark, }) => {
    const [formLocale] = useLocaleReceiver('Form');
    if (!label)
        return null;
    return (<FormContext.Consumer key="label">
      {({ vertical, labelAlign: contextLabelAlign, labelCol: contextLabelCol, colon: contextColon, }) => {
        var _a;
        const mergedLabelCol = labelCol || contextLabelCol || {};
        const mergedLabelAlign = labelAlign || contextLabelAlign;
        const labelClsBasic = `${prefixCls}-item-label`;
        const labelColClassName = classNames(labelClsBasic, mergedLabelAlign === 'left' && `${labelClsBasic}-left`, mergedLabelCol.className);
        let labelChildren = label;
        // Keep label is original where there should have no colon
        const computedColon = colon === true || (contextColon !== false && colon !== false);
        const haveColon = computedColon && !vertical;
        // Remove duplicated user input colon
        if (haveColon && typeof label === 'string' && label.trim() !== '') {
            labelChildren = label.replace(/[:|：]\s*$/, '');
        }
        // Add required mark if optional
        if (requiredMark === 'optional' && !required) {
            labelChildren = (<>
              {labelChildren}
              <span className={`${prefixCls}-item-optional`}>
                {(formLocale === null || formLocale === void 0 ? void 0 : formLocale.optional) || ((_a = defaultLocale.Form) === null || _a === void 0 ? void 0 : _a.optional)}
              </span>
            </>);
        }
        const labelClassName = classNames({
            [`${prefixCls}-item-required`]: required,
            [`${prefixCls}-item-required-mark-optional`]: requiredMark === 'optional',
            [`${prefixCls}-item-no-colon`]: !computedColon,
        });
        return (<Col {...mergedLabelCol} className={labelColClassName}>
            <label htmlFor={htmlFor} className={labelClassName} title={typeof label === 'string' ? label : ''}>
              {labelChildren}
            </label>
          </Col>);
    }}
    </FormContext.Consumer>);
};
export default FormItemLabel;
