import * as React from 'react';
import { LastIndexContext } from '.';
const spaceSize = {
    small: 8,
    middle: 16,
    large: 24,
};
export default function Item({ className, direction, index, size, marginDirection, children, }) {
    const latestIndex = React.useContext(LastIndexContext);
    if (children === null || children === undefined) {
        return null;
    }
    return (<div className={className} style={index >= latestIndex
        ? {}
        : {
            [direction === 'vertical' ? 'marginBottom' : marginDirection]: typeof size === 'string' ? spaceSize[size] : size,
        }}>
      {children}
    </div>);
}
