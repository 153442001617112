import * as React from 'react';
import omit from 'omit.js';
import { FormProvider as RcFormProvider } from 'rc-field-form';
export const FormContext = React.createContext({
    labelAlign: 'right',
    vertical: false,
    itemRef: (() => { }),
});
export const FormItemContext = React.createContext({
    updateItemErrors: () => { },
});
export const FormProvider = props => {
    const providerProps = omit(props, ['prefixCls']);
    return <RcFormProvider {...providerProps}/>;
};
