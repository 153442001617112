// TODO: remove this lint
// SFC has specified a displayName, but not worked.
/* eslint-disable react/display-name */
import * as React from 'react';
import { FormProvider as RcFormProvider } from 'rc-field-form';
import { RenderEmptyHandler } from './renderEmpty';
import LocaleProvider, { ANT_MARK } from '../locale-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { ConfigConsumer, ConfigContext, CSPConfig, ConfigConsumerProps } from './context';
import { SizeContextProvider } from './SizeContext';
import message from '../message';
import notification from '../notification';
export { RenderEmptyHandler, ConfigContext, ConfigConsumer, CSPConfig, ConfigConsumerProps };
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
const ConfigProvider = props => {
    React.useEffect(() => {
        if (props.direction) {
            message.config({
                rtl: props.direction === 'rtl',
            });
            notification.config({
                rtl: props.direction === 'rtl',
            });
        }
    }, [props.direction]);
    const getPrefixClsWrapper = (context) => {
        return (suffixCls, customizePrefixCls) => {
            const { prefixCls } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            const mergedPrefixCls = prefixCls || context.getPrefixCls('');
            return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
        };
    };
    const renderProvider = (context, legacyLocale) => {
        const { children, getTargetContainer, getPopupContainer, renderEmpty, csp, autoInsertSpaceInButton, form, input, locale, pageHeader, componentSize, direction, space, virtual, dropdownMatchSelectWidth, } = props;
        const config = Object.assign(Object.assign({}, context), { getPrefixCls: getPrefixClsWrapper(context), csp,
            autoInsertSpaceInButton, locale: locale || legacyLocale, direction,
            space,
            virtual,
            dropdownMatchSelectWidth });
        if (getTargetContainer) {
            config.getTargetContainer = getTargetContainer;
        }
        if (getPopupContainer) {
            config.getPopupContainer = getPopupContainer;
        }
        if (renderEmpty) {
            config.renderEmpty = renderEmpty;
        }
        if (pageHeader) {
            config.pageHeader = pageHeader;
        }
        if (input) {
            config.input = input;
        }
        let childNode = children;
        // Additional Form provider
        let validateMessages = {};
        if (locale && locale.Form && locale.Form.defaultValidateMessages) {
            validateMessages = locale.Form.defaultValidateMessages;
        }
        if (form && form.validateMessages) {
            validateMessages = Object.assign(Object.assign({}, validateMessages), form.validateMessages);
        }
        if (Object.keys(validateMessages).length > 0) {
            childNode = <RcFormProvider validateMessages={validateMessages}>{children}</RcFormProvider>;
        }
        return (<SizeContextProvider size={componentSize}>
        <ConfigContext.Provider value={config}>
          <LocaleProvider locale={locale || legacyLocale} _ANT_MARK__={ANT_MARK}>
            {childNode}
          </LocaleProvider>
        </ConfigContext.Provider>
      </SizeContextProvider>);
    };
    return (<LocaleReceiver>
      {(_, __, legacyLocale) => (<ConfigConsumer>
          {context => renderProvider(context, legacyLocale)}
        </ConfigConsumer>)}
    </LocaleReceiver>);
};
/** @private internal usage. do not use in your production */
ConfigProvider.ConfigContext = ConfigContext;
export default ConfigProvider;
