var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
import RowContext from './RowContext';
import { tuple } from '../_util/type';
import ResponsiveObserve, { responsiveArray, } from '../_util/responsiveObserve';
const RowAligns = tuple('top', 'middle', 'bottom', 'stretch');
const RowJustify = tuple('start', 'end', 'center', 'space-around', 'space-between');
const Row = React.forwardRef((props, ref) => {
    const [screens, setScreens] = React.useState({
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
        xxl: true,
    });
    const gutterRef = React.useRef();
    gutterRef.current = props.gutter;
    React.useEffect(() => {
        const token = ResponsiveObserve.subscribe(screen => {
            const currentGutter = gutterRef.current || 0;
            if ((!Array.isArray(currentGutter) && typeof currentGutter === 'object') ||
                (Array.isArray(currentGutter) &&
                    (typeof currentGutter[0] === 'object' || typeof currentGutter[1] === 'object'))) {
                setScreens(screen);
            }
        });
        return () => {
            ResponsiveObserve.unsubscribe(token);
        };
    }, []);
    const getGutter = () => {
        const results = [0, 0];
        const { gutter = 0 } = props;
        const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
        normalizedGutter.forEach((g, index) => {
            if (typeof g === 'object') {
                for (let i = 0; i < responsiveArray.length; i++) {
                    const breakpoint = responsiveArray[i];
                    if (screens[breakpoint] && g[breakpoint] !== undefined) {
                        results[index] = g[breakpoint];
                        break;
                    }
                }
            }
            else {
                results[index] = g || 0;
            }
        });
        return results;
    };
    const renderRow = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, justify, align, className, style, children } = props, others = __rest(props, ["prefixCls", "justify", "align", "className", "style", "children"]);
        const prefixCls = getPrefixCls('row', customizePrefixCls);
        const gutter = getGutter();
        const classes = classNames(prefixCls, {
            [`${prefixCls}-${justify}`]: justify,
            [`${prefixCls}-${align}`]: align,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className);
        const rowStyle = Object.assign(Object.assign(Object.assign({}, (gutter[0] > 0
            ? {
                marginLeft: gutter[0] / -2,
                marginRight: gutter[0] / -2,
            }
            : {})), (gutter[1] > 0
            ? {
                marginTop: gutter[1] / -2,
                marginBottom: gutter[1] / 2,
            }
            : {})), style);
        const otherProps = Object.assign({}, others);
        delete otherProps.gutter;
        return (<RowContext.Provider value={{ gutter }}>
        <div {...otherProps} className={classes} style={rowStyle} ref={ref}>
          {children}
        </div>
      </RowContext.Provider>);
    };
    return <ConfigConsumer>{renderRow}</ConfigConsumer>;
});
Row.displayName = 'Row';
export default Row;
