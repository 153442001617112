import * as React from 'react';
import classNames from 'classnames';
import { withConfigConsumer } from '../config-provider/context';
import StatisticNumber from './Number';
const Statistic = props => {
    const { prefixCls, className, style, valueStyle, value = 0, title, valueRender, prefix, suffix, direction, onMouseEnter, onMouseLeave, } = props;
    const valueNode = <StatisticNumber {...props} value={value}/>;
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<div className={cls} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {title && <div className={`${prefixCls}-title`}>{title}</div>}
      <div style={valueStyle} className={`${prefixCls}-content`}>
        {prefix && <span className={`${prefixCls}-content-prefix`}>{prefix}</span>}
        {valueRender ? valueRender(valueNode) : valueNode}
        {suffix && <span className={`${prefixCls}-content-suffix`}>{suffix}</span>}
      </div>
    </div>);
};
Statistic.defaultProps = {
    decimalSeparator: '.',
    groupSeparator: ',',
};
const WrapperStatistic = withConfigConsumer({
    prefixCls: 'statistic',
})(Statistic);
export default WrapperStatistic;
