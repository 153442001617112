var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { composeRef } from 'rc-util/lib/ref';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Input from './Input';
import Button from '../button';
import SizeContext from '../config-provider/SizeContext';
import { ConfigConsumer } from '../config-provider';
import { cloneElement, replaceElement } from '../_util/reactNode';
const Search = React.forwardRef((props, ref) => {
    const inputRef = React.useRef(null);
    const onChange = (e) => {
        const { onChange: customOnChange, onSearch: customOnSearch } = props;
        if (e && e.target && e.type === 'click' && customOnSearch) {
            customOnSearch(e.target.value, e);
        }
        if (customOnChange) {
            customOnChange(e);
        }
    };
    const onMouseDown = e => {
        var _a;
        if (document.activeElement === ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input)) {
            e.preventDefault();
        }
    };
    const onSearch = (e) => {
        var _a;
        const { onSearch: customOnSearch, loading, disabled } = props;
        if (loading || disabled) {
            return;
        }
        if (customOnSearch) {
            customOnSearch((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input.value, e);
        }
    };
    const renderLoading = (prefixCls) => {
        const { enterButton, size: customizeSize } = props;
        if (enterButton) {
            return (<SizeContext.Consumer key="enterButton">
          {size => (<Button className={`${prefixCls}-button`} type="primary" size={customizeSize || size}>
              <LoadingOutlined />
            </Button>)}
        </SizeContext.Consumer>);
        }
        return <LoadingOutlined className={`${prefixCls}-icon`} key="loadingIcon"/>;
    };
    const renderSuffix = (prefixCls) => {
        const { suffix, enterButton, loading } = props;
        if (loading && !enterButton) {
            return [suffix, renderLoading(prefixCls)];
        }
        if (enterButton)
            return suffix;
        const icon = (<SearchOutlined className={`${prefixCls}-icon`} key="searchIcon" onClick={onSearch}/>);
        if (suffix) {
            return [
                replaceElement(suffix, null, {
                    key: 'suffix',
                }),
                icon,
            ];
        }
        return icon;
    };
    const renderAddonAfter = (prefixCls, size) => {
        const { enterButton, disabled, addonAfter, loading } = props;
        const btnClassName = `${prefixCls}-button`;
        if (loading && enterButton) {
            return [renderLoading(prefixCls), addonAfter];
        }
        if (!enterButton)
            return addonAfter;
        let button;
        const enterButtonAsElement = enterButton;
        const isAntdButton = enterButtonAsElement.type &&
            enterButtonAsElement.type.__ANT_BUTTON === true;
        if (isAntdButton || enterButtonAsElement.type === 'button') {
            button = cloneElement(enterButtonAsElement, Object.assign({ onMouseDown, onClick: onSearch, key: 'enterButton' }, (isAntdButton
                ? {
                    className: btnClassName,
                    size,
                }
                : {})));
        }
        else {
            button = (<Button className={btnClassName} type="primary" size={size} disabled={disabled} key="enterButton" onMouseDown={onMouseDown} onClick={onSearch}>
          {enterButton === true ? <SearchOutlined /> : enterButton}
        </Button>);
        }
        if (addonAfter) {
            return [
                button,
                replaceElement(addonAfter, null, {
                    key: 'addonAfter',
                }),
            ];
        }
        return button;
    };
    const renderSearch = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, inputPrefixCls: customizeInputPrefixCls, enterButton, className, size: customizeSize } = props, restProps = __rest(props, ["prefixCls", "inputPrefixCls", "enterButton", "className", "size"]);
        delete restProps.onSearch;
        delete restProps.loading;
        const prefixCls = getPrefixCls('input-search', customizePrefixCls);
        const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
        const getClassName = (size) => {
            let inputClassName;
            if (enterButton) {
                inputClassName = classNames(prefixCls, {
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                    [`${prefixCls}-enter-button`]: !!enterButton,
                    [`${prefixCls}-${size}`]: !!size,
                }, className);
            }
            else {
                inputClassName = classNames(prefixCls, {
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                }, className);
            }
            return inputClassName;
        };
        return (<SizeContext.Consumer>
        {size => (<Input ref={composeRef(inputRef, ref)} onPressEnter={onSearch} {...restProps} size={customizeSize || size} prefixCls={inputPrefixCls} addonAfter={renderAddonAfter(prefixCls, customizeSize || size)} suffix={renderSuffix(prefixCls)} onChange={onChange} className={getClassName(customizeSize || size)}/>)}
      </SizeContext.Consumer>);
    };
    return <ConfigConsumer>{renderSearch}</ConfigConsumer>;
});
Search.defaultProps = {
    enterButton: false,
};
Search.displayName = 'Search';
export default Search;
